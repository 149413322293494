<template lang="pug">
  .app-chip(
    :style="{'background-color': color}"
    :class="{'app-chip--dark': dark}"
  )
    span.app-chip_label.unselectable {{ value }}
</template>

<script>
export default {
  name: 'BaseChip',

  props: {
    color: {type: String, default: '#161616'},
    value: String,
    dark: Boolean
  }
}
</script>

<style lang="scss" scoped>
.app-chip {
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 15px;
  border: solid 0.5px $border-color;
  background-color: $layout-bg;

  &-- {
    &dark {
      .app-chip_label {
        color: $white !important;
      }
    }
  }

  &_label {
    font-family: $font-roboto;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $label-color;
  }
}
</style>
